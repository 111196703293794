<template>
   <div class="custom-control form-check form-switch form-switch-xl">
      <input class="form-check-input" type="checkbox" v-model="inputValue" />
      <label class="custom-control-label" for="customSwitch1"></label>
   </div>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'ObiSwitch',
   components: {},
   props: {
      value: {},
      checked: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.form-check-input {
   transform: scale(2);
}
</style>
